














































































































































































































































































































































































































































































































































































































































































































































// --- Vue & Template imports ---
import { Component, Vue } from 'vue-property-decorator';
import ConfirmActionDialog from '@/components/app/ConfirmActionDialog.vue';
import InfoDialog from '@/components/app/InfoDialog.vue';

// --- Models ---
import { Address, User } from '@/models/User.model';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';
import UserService from '@/services/user';

// --- Third Party imports ---
import dayjs from 'dayjs';
import dayJSRelativeTime from 'dayjs/plugin/relativeTime';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';

dayjs.extend(dayJSRelativeTime);

export interface Phone {
  number: any;
  regionCode: string;
  valid: boolean;
  country: any;
}

@Component({
  components: {
    ConfirmActionDialog,
    InfoDialog,
    VueTelInputVuetify,    
  },
})
export default class UserProfileForm extends Vue {

  valid: boolean = false;
  updateDelay: boolean = false;
  avatarValid: boolean = false;  
  passwordResetDelay: boolean = false;

  showInfoDialog: boolean = false;
  infoDialogAvailable: boolean = false;
  infoDialogTitle: string = '';
  infoDialogContent: string = '';

  uploadFiles: File[] = [];
  /*
    avatar = uploadFiles[0]
    proofOfAddress = uploadFiles[1]
  */

  clickedItem: User = {
    firstName: '',
    lastName: '',
    email: '',
    registrationTypeId: 0,
    registrationType: '',
    userTypeId: 0,
    userType: '',
    avatarFilename: '',
    cellphoneCountryRegion: 'ZA',
    cellphoneCountryDialCode: '',
    cellphoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    postalCode: '',
    country: 'South Africa',
    regionId: 0,
    region: '',
    addressFilename: '',
    idNumber: '',
    idFilename: '',
    hasUpcomingShoots: false,
  };

  origVatNumber?: string = '';

  origAddress: Address = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    postalCode: '',
    country: 'South Africa',
  };
  addressFilenameRequired: boolean = false;

  sameIDNumber: boolean = false;

  cellphoneErrorMessage: string = '';
  companyContactErrorMessage: string = '';
  disableOthers: string = '';

  // used by Confirm Action Dialog for Delete Item
  action: string = '';
  dialogContent: string = '';
  confirmActionDelay: boolean = false;
  showConfirmActionDialog: boolean = false;

  rules = {
    required: (value: string) => !!value || '',
    nameSurname: (value: string) => /^[A-Za-z -]+$/.test(value.trim()) || 'Name must only contain letters',
    optionalFilesize1Mb: (value: File) => !value || value.size < 1000000 || 'File size should be less than 1 MB',
    optionalImageFileType: (value: File) => value ? (value.type === 'image/jpeg' || value.type === 'image/png' || 'Invalid file type. Must be an image') : true,
    optionalPdfFileType: (value: File) => value ? (value.type === 'application/pdf' || 'Invalid file type. Must be a PDF') : true,
    numbersOnly: (value: string) => (/^\d+$/.test(value)) || 'Invalid character',
    vatNumber: (value: string) => value ? /^[4][0-9]{9}$/.test(value) || 'Invalid South African VAT Number' : true,
  };

  // #region Functions to assist validation rules
    validateCellphoneInput(formattedNumber: any, phone: Phone) {
      this.cellphoneErrorMessage = phone.valid ? '' : 'Invalid Phone Number';

      if (phone.valid) {
        this.clickedItem.cellphoneCountryRegion = phone.regionCode;
        this.clickedItem.cellphoneCountryDialCode = phone.country.dialCode;
        this.clickedItem.cellphoneNumber = (phone.number.input as string).split(' ').join('');
      }
   }

   validateCompanyContactInput(formattedNumber: any, phone: Phone) {
      this.companyContactErrorMessage = phone.valid ? '' : 'Invalid Phone Number';

      if (phone.valid) {
        this.clickedItem.companyContactCountryRegion = phone.regionCode;
        this.clickedItem.companyContactCountryDialCode = phone.country.dialCode;
        this.clickedItem.companyContactNumber = (phone.number.input as string).split(' ').join('');
      }
   }

   checkAddressUpdate() {
    if (this.clickedItem.addressLine1 !== this.origAddress.addressLine1 ||
      this.clickedItem.addressLine2 !== this.origAddress.addressLine2 ||
      this.clickedItem.addressLine3 !== this.origAddress.addressLine3 ||
      this.clickedItem.city !== this.origAddress.city ||
      this.clickedItem.postalCode !== this.origAddress.city ||
      this.clickedItem.country !== this.origAddress.country) {

      // the address has changed, mark the proof of address file as required
      this.addressFilenameRequired = true;

      if (this.$refs.itemForm) {
        (this.$refs.itemForm as any).validate();
      }
    } else {
      this.addressFilenameRequired = false;
    }
   }
  // #endregion

  // region Functions to display data on page load
    async beforeMount() {
      await AppService.listRegions(false);
      await this.getUserProfile();
    }

    async getUserProfile() {
      try {
        const response: string = await AuthService.getProfile();

        if (response) {
          // something broke
          AppService.errorHandler(response);
        } else {
          this.clickedItem = this.$store.getters['users/loggedInUser'].profile;

          // store original values for comparison
          this.origVatNumber = this.clickedItem.vatNumber;

          this.origAddress = {
            addressLine1: this.clickedItem.addressLine1,
            addressLine2: this.clickedItem.addressLine2,
            addressLine3: this.clickedItem.addressLine3,
            city: this.clickedItem.city,
            postalCode: this.clickedItem.postalCode,
            country: this.clickedItem.country,
          };

          if (this.clickedItem.directorIdNumber && this.clickedItem.directorIdNumber === this.clickedItem.idNumber) {
            this.sameIDNumber = true;
          }

        }
      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('UserProfile.vue - getUserProfile - 710 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      }
    }

    downloadFile(filename: string) {
      window.open(filename);
    }

    async logout() {
      await AuthService.logout();
    }

    showAvatarPreview() {
      if (this.uploadFiles && this.uploadFiles[0]) {
        return URL.createObjectURL(this.uploadFiles[0]);
      } else if (this.clickedItem.avatarFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'profile_pictures/' + this.clickedItem.avatarFilename;  
      } else {
        return '';
      }
    }

    getAddressUrl() {
      return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'proof_of_addresses/' + this.clickedItem.addressFilename;  
    }

    getCompanyRegistrationUrl() {
      return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'company_registration_documents/' + this.clickedItem.companyRegistrationFilename;  
    }

    getIdDocumentUrl(filename: string) {
      return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'id_documents/' + filename;  
    }

    getLastLogin() {
      return dayjs(this.$store.getters['users/loggedInUser'].lastLogin).fromNow();
    }

    updateAvatar() {
      this.disableOthers = 'avatar';
    }

    cancelAvatarUpdate() {
      this.disableOthers = '';
      // avatar = uploadFiles[0]
      if (this.uploadFiles && this.uploadFiles[0]) {
        delete this.uploadFiles[0];
      }
    }

    async saveAvatar() {
      await this.doAvatarUpdate();
      this.disableOthers = '';
      //avatar = uploadFiles[0]
      if (this.uploadFiles && this.uploadFiles[0]) {
        delete this.uploadFiles[0];
      }
    }
  // #endregion

  // #region Functions required for the Information Dialog
    InformationDialog_cancel() {
      this.showInfoDialog = false;
      this.infoDialogTitle = '';
      this.infoDialogContent = '';

      setTimeout(() => {
        this.infoDialogAvailable = false;
      }, 200);
    }

    // TODO: could we make these generic by sending in the param?

   launchCellphoneInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].cellphone.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].cellphone.content;

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchCompanyContactInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].contactNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].contactNumber.content;

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchAddressInfoDialog() {
      if (this.clickedItem.registrationTypeId === 1) {
        this.infoDialogTitle = this.$store.getters['app/infoDialogs'].address.title;
        this.infoDialogContent = this.$store.getters['app/infoDialogs'].address.content;
      } else {
        this.infoDialogTitle = this.$store.getters['app/infoDialogs'].companyAddress.title;
        this.infoDialogContent = this.$store.getters['app/infoDialogs'].companyAddress.content;
      }
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchCompanyRegistrationAndVATNumberInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].companyRegistrationAndVATNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].companyRegistrationAndVATNumber.content;
      
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }
  // #endregion

  // #let's update this users profile
    async doUpdate() {
      try {
        this.updateDelay = true;

        const formData = new FormData();

        formData.append('companyName', '' + this.clickedItem.companyName);
        formData.append('vatNumber', '' + this.clickedItem.vatNumber);
        formData.append('cellphoneCountryRegion', '' + this.clickedItem.cellphoneCountryRegion);
        formData.append('cellphoneCountryDialCode', '' + this.clickedItem.cellphoneCountryDialCode);
        formData.append('cellphoneNumber', '' + this.clickedItem.cellphoneNumber);
        formData.append('companyContactCountryRegion', '' + this.clickedItem.companyContactCountryRegion);
        formData.append('companyContactCountryDialCode', '' + this.clickedItem.companyContactCountryDialCode);
        formData.append('companyContactNumber', '' + this.clickedItem.companyContactNumber);
        formData.append('addressLine1', '' + this.clickedItem.addressLine1);
        formData.append('addressLine2', '' + this.clickedItem.addressLine2);
        formData.append('addressLine3', '' + this.clickedItem.addressLine3);
        formData.append('city', '' + this.clickedItem.city);
        formData.append('postalCode', '' + this.clickedItem.postalCode);
        formData.append('country', '' + this.clickedItem.country);
        formData.append('regionId', '' + this.clickedItem.regionId);

        /*
          avatar = uploadFiles[0]
          proofOfAddress = uploadFiles[1]
        */
        if (this.uploadFiles && this.uploadFiles[1]) {
          formData.append('proofOfAddressFile', this.uploadFiles[1]);
        }

        const response = await AuthService.updateProfile( formData );

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.getUserProfile(); // to get new download urls
            this.uploadFiles = [];
            this.addressFilenameRequired = false;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }
      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('UserProfile.vue - doUpdate - 838 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      } finally {
        this.updateDelay = false;
      }
    }

    async doAvatarUpdate() {
      try {
        const formData = new FormData();        

        // avatar = uploadFiles[0]
        if (this.uploadFiles && this.uploadFiles[0]) {
          formData.append('avatarFile', this.uploadFiles[0]);
        }

        const response = await AuthService.updateAvatar( formData );

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.getUserProfile(); // to get new download urls
            this.uploadFiles = [];
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }
      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('UserProfile.vue - doAvatarUpdate - 872 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      }
    }

    async doResetPassword() {
    try {
      this.passwordResetDelay = true;

      const response = await AuthService.resetPassword();
      if (response && response.data) {
        if (response.data.result && response.data.result === 'false') {
        AppService.errorHandler(response.data.message);
        } else {
          AppService.successHandler(response.data.message);
        } 
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {

      if (error.response) {
          return error.response.statusText;
        } else {
          AppService.logSupportDebug('UserProfile.vue - doResetPassword - 899 - ' + error);
        }
    } finally {
      this.passwordResetDelay = false;
    }
  }
  // #endregion

  // #region Functions required for the Confirm Action Dialog for Delete Item
    async checkDeleteDialog() {
      // if we are a company admin user, make sure there is a) 1 other admin user or b) no staff users before allowing this user to delete themselves
      if (this.clickedItem.registrationTypeId === 2 && this.clickedItem.userTypeId === 1) {
        // check if there are any other users
        await UserService.listUsers(false);

        if (this.$store.getters['users/users'].length > 0) {
          // we have other company users
          const otherAdminUser: User[] = this.$store.getters['users/users'].filter((item: User) => item.userTypeId === 1);
          if (otherAdminUser.length > 0) {
            // you can delete yourself
            this.launchDeleteMeDialog();
          } else {
            // show dialog saying you cannot delete yourself until you have deleted all your staff users
            this.launchDeleteStaffFirstInfoDialog();
          }
        } else {
          if (this.clickedItem.hasUpcomingShoots) {
            this.launchHasUpcomingShootsInfoDialog();
          } else {
            this.launchDeleteMeDialog();
          }
        }
      } else {
        if (this.clickedItem.hasUpcomingShoots) {
          this.launchHasUpcomingShootsInfoDialog();
        } else {
          this.launchDeleteMeDialog();
        }
      }
    }

    launchHasUpcomingShootsInfoDialog() {
      this.infoDialogContent = 'You have some upcoming Shoots so we are currently unable to delete your account.';

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchDeleteStaffFirstInfoDialog() {
      this.infoDialogContent = 'Please delete all associated Staff User Accounts before deleting your own account.';

      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }

    launchDeleteMeDialog() {
      this.action = 'DELETE';
      this.dialogContent = 'You are about to <span style="font-weight: 700; color: #C01212">delete</span> your eazigear account.'
        + '<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;
    }

    async ConfirmActionDialog_doConfirmed() {
      if (this.action === 'DELETE') {
        this.doDelete();
      }
    }

    async doDelete() {
      try {
        this.confirmActionDelay = true;

        const response = await AuthService.delete();
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            this.logout();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('UserProfilevue - doDelete - 745 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }
  // #endregion
}
